<template>

<app-table-cell>

	<div class="label" :data-type="type">{{ text }}</div>

</app-table-cell>

</template>

<script>

export default {

	props: ['text', 'type']

}

</script>

<style scoped>

.label {
	color: #fff;
	height: 20px;
	font-size: 12px;
	padding: 0px 4px;
	line-height: 20px;
	border-radius: 4px;
	background-color: #4082d3;
}

.label[data-type="success"] {
	background-color: #4faa80;
}

.label[data-type="danger"] {
	background-color: #c55b5b;
}

.label[data-type="warning"] {
	background-color: #ffbe7a;
}

</style>
