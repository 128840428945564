<template>

<div class="input" :class="{'is-stacked': stacked, 'is-wide': wide, 'is-error': hasError, 'is-toggled-off': toggle !== undefined && !toggle, 'is-toggled-on': toggle}">

	<div class="input-inner">

		<div class="input-label" v-if="label">
			
			<span>{{ label }}</span>

			<div class="input-prefix" v-if="prefix">

				{{ prefix }}

			</div>

			<div class="input-toggle" v-tooltip="'Toggle setting'" v-if="toggle !== undefined" v-on:click="$emit('toggle')">

				<app-icon icon="check" />

			</div>

			<app-icon icon="notice" class="input-label-icon" v-if="hasError" v-tooltip="errorTip" />
			
		</div>

		<div class="input-content" :class="{'is-noline': noLine, 'is-disabled': disabled || (toggle !== undefined && !toggle), 'is-ready': isReady}">

			<slot></slot>

		</div>

	</div>

	<div class="input-notes">{{ notes }}</div>

	<div class="input-messages" v-if="messages">

		<div class="input-messages-item" :data-type="message.type" v-for="(message, index) in messages" :key="index">
			<app-icon icon="caret-up" class="input-messages-item-caret" />
			<app-icon :icon="message.icon" class="input-messages-item-icon" />
			<div>{{ message.text }}</div>
		</div>

	</div> 

</div>

</template>

<script>

export default {

	props: ['label', 'wide', 'validation', 'messages', 'prefix', 'toggle', 'stacked', 'noLine', 'isReady', 'notes', 'disabled'],

	computed: {

		hasError: function() {

			return (this.validation) ? this.validation.$error : false

		},

		errorTip: function() {

			return 'Please enter a valid value'

		}

	}

}

</script>

<style scoped>

.input {
	margin-bottom: 10px;
}

.input-inner {
	display: flex;
}

.is-mobile .input-inner {
	flex-direction: column;
}

.input.is-stacked .input-inner {
	flex-direction: column;
}

.input:last-child {
	margin-bottom: 0px;
}

.input-label {
	width: 150px;
	font-size: 14px;
	font-weight: 400;
	color: #687589;
	flex-shrink: 0;
	line-height: 40px;
}

.input.is-wide .input-label {
	width: 200px;
}

.input.is-error .input-label {
	color: #f25151;
}

.input-label-icon {
	position: absolute;
	right: 10px;
	top: 0px;
	line-height: 40px;
}

.is-tablet .input-label {
	font-size: 14px;
}

.is-mobile .input-label {
	width: 100%;
	font-size: 14px;
	line-height: 14px;
	padding: 10px 0px 5px 0px;
}

.input-content {
	flex-grow: 1;
}

.input.is-toggled-off .input-content {
	max-height: 36px;
	overflow: hidden;
}

.input-content.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.input-content.is-ready {
	border-color: #4082d3;
}

.input-content.is-noline {
	border-bottom: 0px;
}

.input-prefix {
	line-height: 40px;
	cursor: pointer;
	position: absolute;
	right: 12px;
	top: 0px;
}

.input-toggle {
	border: 2px solid #eee;
	color: #ddd;
	height: 24px;
	width: 24px;
	border-radius: 4px;
	margin-left: 5px;
	line-height: 22px;
	text-align: center;
	font-size: 12px;
	cursor: pointer;
	position: absolute;
	right: 4px;
	top: 8px;
}

.input-toggle:hover {
	border-color: #ccc;
	color: #ccc;
}

.input.is-toggled-on .input-toggle {
	background-color: #4082d3;
	color: #fff;
	border-color: #4082d3;
}

.input-notes {
	font-size: 12px;
	line-height: 16px;
	margin-top: 4px;
	color: #707070;
	padding-left: 160px;
}

.input.is-wide .input-notes {
	padding-left: 210px;
}

.is-mobile .input-notes {
	padding-left: 0px;
}

.input-messages {
	margin-top: 10px;
	padding-left: 150px;
}

.is-mobile .input-messages {
	padding-left: 0px;
}

.input.is-wide .input-messages {
	padding-left: 200px;
}

.is-mobile .input.is-wide .input-messages {
	padding-left: 0px;
}

.input-messages-item {
	font-size: 14px;
	line-height: 18px;
	padding: 8px 10px;
	border-radius: 12px;
	color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.input-messages-item-icon {
	font-size: 24px;
	line-height: 36px;
	margin-right: 10px;
}

.input-messages-item-caret {
	position: absolute;
	font-size: 20px;
	top: -10px;
	left: 10px;
}

.input-messages-item[data-type="red"] {
	background-color: #c55b5b; 
}

.input-messages-item[data-type="red"] .input-messages-item-caret {
	color: #c55b5b; 
}

.input-messages-item[data-type="blue"] {
	background-color: #4082d3; 
}

.input-messages-item[data-type="blue"] .input-messages-item-caret {
	color: #4082d3; 
}

.input-messages-item[data-type="green"] {
	background-color: #4faa80; 
}

.input-messages-item[data-type="green"] .input-messages-item-caret {
	color: #4faa80; 
}

.input-messages-item[data-type="yellow"] {
	background-color: #ffbe7a; 
}

.input-messages-item[data-type="yellow"] .input-messages-item-caret {
	color: #ffbe7a; 
}

</style>
