<template>

<div class="title">

	<router-link :to="backLink" v-if="back || backPath" class="title-back"><app-icon icon="back" /></router-link>

	<div class="title-text">
		<div class="title-text-title">{{ title }}</div>
		<div class="title-text-subtitle" v-if="subtitle">{{ subtitle }}</div>
	</div>
		
</div>

</template>

<script>

export default {

	props: ['title', 'subtitle', 'back', 'backParams', 'backPath'],

	computed: {

		backLink: function() {

			var params = {}

			this.$_.each(this.backParams, function(value, key) {

				params[key] = this.$route.params[value]

			}.bind(this))

			return (this.backPath) ? {path: this.backPath} : {name: this.back, params: params}

		}

	}

}

</script>

<style scoped>

.title {
	display: flex;
	flex-direction: row;
	padding-left: 20px;
	padding-right: 20px;
}

.is-tablet .title {
	padding-left: 20px;
}

.is-mobile .title {
	padding-left: 10px;
}

.title-text {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.title-text-title {
	color: #687589;
	font-size: 24px;
	font-weight: 400;
	text-transform: uppercase;
}

.is-tablet .title-text-title {
	font-size: 20px;
}

.is-mobile .title-text-title {
	font-size: 14px;
}

.title-text-subtitle {
	color: #687589;
	opacity: 0.75;
	margin-top: 4px;
	font-size: 14px;
	font-weight: 400;
}

.is-tablet .title-text-subtitle {
	font-size: 14px;
}

.is-mobile .title-text-subtitle {
	font-size: 11px;
}

.title-text-lobbies {
	margin-top: 2px;
}

.title-back {
	width: 40px;
	font-size: 20px;
	flex-shrink: 0;
	color: #687589;
	display: flex;
	align-items: center;
}

.is-tablet .title-back {
	font-size: 20px;
}

.is-mobile .title-back {
	font-size: 14px;
}

</style>
