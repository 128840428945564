<template>

<app-input :wide="wide" v-on:toggle="$emit('toggle')" :label="label" :stacked="stacked" :no-line="true" :toggle="toggle" :notes="notes" :disabled="disabled" :validation="validation">

	<div v-if="isKeyed" class="toggle" :class="{'is-slim': slim, 'is-fullwidth': fullWidth, 'is-stacked': (stacked || column) && !row}">
		<div class="toggle-item" v-for="option in finalOptions" :key="option[valueProperty]" :class="{'is-active': isSelected(option[valueProperty])}" v-on:click="onClick(option[valueProperty])">
			<div class="toggle-item-text">{{ option.text }}</div>
			<app-icon icon="tick" class="toggle-item-icon" />
		</div>
	</div>

	<div v-if="!isKeyed" class="toggle" :class="{'is-slim': slim, 'is-fullwidth': fullWidth, 'is-stacked': (stacked || column) && !row}">
		<div class="toggle-item" v-for="(option, key) in finalOptions" :key="key" :class="{'is-active': isSelected(key)}" v-on:click="onClick(key)">
			<div class="toggle-item-text">{{ option }}</div>
			<app-icon icon="tick" class="toggle-item-icon" />
		</div>
	</div>

</app-input>

</template>

<script>

export default {

	props: ['validation', 'wide', 'property', 'label', 'value', 'toggle', 'stacked', 'notes', 'column', 'row', 'fullWidth', 'slim', 'placeholder', 'options', 'disabled'],

	computed: {

		valueProperty: function() {

			return this.property || 'value'

		},
		isKeyed: function() {

			return (this.options) ? this.$_.isObject(this.options[this.$_.keys(this.options)[0]]) : false

		},

		finalOptions: function() {
			
			return (this.options) ? this.options : {
				1: 'Yes',
				0: 'No'
			}

		}

	},

	methods: {

		onClick: function(value) {

			var original = this.$_.clone(this.value)

			if (this.$_.contains(original, value)) {

				original.splice(original.indexOf(value), 1)

			} else {

				original.push(value)

			}

			this.$emit('input', original)

		},

		isSelected: function(value) {

			return this.$_.contains(this.value, value)

		}

	}

}

</script>

<style scoped>

.toggle {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 12px;
	border: 1px solid #eee;
}

.toggle.is-stacked,
.is-mobile .toggle {
	flex-direction: column;
	height: auto;
}

.toggle-item {
	font-size: 14px;
	width: calc(33.3% - 4px);
	color: #343434;
	padding: 0px 8px;
	min-height: 36px;
	margin: 2px;
	border-radius: 4px;
	cursor: pointer;
	line-height: 16px;
	user-select: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.is-mobile .toggle-item {
	width: 100%;
}

.toggle.is-fullwidth .toggle-item {
	flex-grow: 1;
	flex-shrink: 0;
}

.toggle.is-stacked .toggle-item {
	text-align: left;
	justify-content: space-between;
}

.toggle.is-stacked .toggle-item,
.is-mobile .toggle-item {
	border-width: 2px 2px 0px 2px;
}

.toggle.is-stacked .toggle-item,
.is-device .toggle-item {
	font-size: 14px;
}

.toggle.is-slim .toggle-item {
	width: auto;
	padding: 0px 20px;
}

.toggle-item.is-active {
	background-color: #4082d3;
	color: #fff;
}

.toggle-item:first-child:last-child {
	border-radius: 12px;
}

.toggle-item-text {
	flex-grow: 1;
}

.toggle-item-icon {
	margin-left: 10px;
	color: #eee;
	flex-shrink: 0;
	font-size: 16px;
}

.toggle-item-icon.is-active {
	color: #fff;
}

.toggle-item:hover:not(.is-active) .toggle-item-icon {
	color: #999;
}

</style>
