import '@/assets/reset.css'
import 'vue-toast-notification/dist/theme-sugar.css'

import Vue from 'vue'
import App from './App.vue'

import VueToast from 'vue-toast-notification'
import tooltip from 'v-tooltip'
import _ from 'underscore'
import axios from 'axios'

import constants from './constants'
import router from './router'
import store from './store'
import api from './api'
import util from './util'

import mixInjection from './mixins/injection'
import mixShortcuts from './mixins/shortcuts'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.baseURL = process.env.VUE_APP_API

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

Vue.config.productionTip = false

Vue.use(VueToast)
Vue.use(tooltip)

Vue.mixin(mixInjection)
Vue.mixin(mixShortcuts)

new Vue({
	router,
	_,
	store,
	constants,
	api,
	util,
	render: h => h(App),
}).$mount('#app')