<template>

<div class="content" :class="{'is-loggedin': this.loggedin}">

	<slot></slot>

</div>
	
</template>

<script>

export default {

	props: ['loggedin']

}

</script>

<style scoped>

.content {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	overflow-y: auto;
	background-color: #fff;
}

.content.is-loggedin {
	left: 240px;
}

</style>