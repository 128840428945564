<template>

<div class="box" :class="{'is-grow': flexGrow, 'is-danger': danger, 'no-title': !title}">

	<div class="box-head" v-if="title" :class="{'has-icon': icon}">
		<app-icon class="box-head-icon" :icon="icon" v-if="icon" />
		<div class="box-head-title">{{ title }}</div>
		<div class="box-head-subtitle" v-if="subtitle">/ {{ subtitle }}</div>
	</div>

	<div class="box-content" :class="{'is-notpadded': notPadded, 'is-transparent': transparent, 'is-scroll': scroll, 'is-blue': blue, 'is-blank': blank, 'is-loading': loading}">

		<app-icon icon="loading" v-if="loading" class="box-content-loading" />

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['title', 'subtitle', 'notPadded', 'loading', 'transparent', 'icon', 'blue', 'blank', 'scroll', 'danger', 'flexGrow']

}

</script>

<style scoped>

.box {
	margin-top: 10px;
	margin-bottom: 20px;
}

.box.no-title {
	margin-top: 0px;
}

.is-mobile .box,
.is-tablet .box {
	margin-bottom: 20px;
}

.box.is-grow {
	display: flex;
	flex-direction: column;
}

.box-head {
	padding: 0px 40px 10px 34px;
	font-size: 16px;
	color: #687589;
	font-weight: 500;
	display: flex;
	align-items: flex-end;
	flex-shrink: 0;
}

.is-mobile .box-head {
	font-size: 16px;
	line-height: 20px;
	padding: 0px 10px 10px 10px;
}

.is-tablet .box-head {
	padding: 0px 20px 20px 20px;
}

.is-tablet .box-head.has-icon {
	padding: 0px 20px 20px 40px;
}

.is-mobile .box-head.has-icon {
	padding: 0px 10px 20px 40px;
}

.box-head-icon {
	position: absolute;
	left: 8px;
	font-size: 16px;
	top: 0px;
}

.box-head-subtitle {
	font-size: 14px;
	font-weight: 300;
	margin-left: 5px;
}

.box-content {
	padding: 10px;
	border: 1px solid #eee;
	background-color: #fff;
	border-radius: 4px;
}

.is-tablet .box-content {
	padding: 20px;
}

.is-mobile .box-content {
	padding: 10px;
}

.box-content.is-scroll {
	overflow: auto;
}

.box-content.is-blue {
	background-color: #4082d3;
}

.box-content.is-blank {
	padding: 0px;
	border-width: 0px;
	box-shadow: none;
	border-radius: 0px;
	background-color: transparent;
}

.box-content.is-transparent {
	padding: 0px 10px;
	border-width: 0px;
	box-shadow: none;
	border-radius: 0px;
	background-color: transparent;
}

.box.is-grow .box-content {
	display: -webkit-box;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.box-content.is-notpadded {
	padding: 0px;
}

.box.is-danger .box-content {
	background-color: #f65d5d;
}

.box-content.is-loading >>> *:not(.icon):not(i) {
	visibility: hidden;
}

.box-content-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 32px;
	color: #4082d3;
}

</style>
