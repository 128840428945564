<template>

<app-input class="tags" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled" :validation="validation">

	<input class="tags-compose" type="text" v-model="compose" placeholder="Type and press enter..." v-on:keypress.enter="onEnterPress" />

	<div class="tags-list">

		<div class="tags-list-item" v-for="(tag, index) in tags" :key="index">

			<div class="tags-list-item-text">{{ tag }}</div>
			<app-icon icon="remove" class="tags-list-item-button" v-tooltip="'Remove'" v-if="!dynamicTag || index !== 0" v-on:click.native="onRemoveClick(tag)" />

		</div>

	</div>

</app-input>

</template>

<script>

import slugify from 'slugify'

export default {

	props: ['validation', 'label', 'disabled', 'value', 'stacked', 'placeholder', 'noLine', 'notes', 'dynamicTag'],

	data: function() {

		return {
			compose: ''
		}

	},

	computed: {

		tags: function() {

			var tags = []

			if (this.dynamicTag) tags.push(slugify(this.dynamicTag, {
				replacement: ' ',
				strict: true,
				remove: /[*+~.()'"!:@]/g,
				lower: true
			}))

			this.$_.each(this.value, function(value) {

				tags.push(value)

			})

			return tags

		}

	},

	methods: {

		onRemoveClick: function(tag) {

			var newValue = this.$_.clone(this.value)
			newValue.splice(newValue.indexOf(tag), 1)

			this.$emit('input', newValue)

		},

		onEnterPress: function() {

			if (this.compose) {

				var tag = this.compose.toLowerCase()

				if (!this.$_.contains(this.value, tag)) {

					var newValue = this.$_.clone(this.value)
					newValue.push(tag)

					this.$emit('input', newValue)
					
				}

				this.compose = ''

			}

		}

	}

}

</script>

<style scoped>

.tags-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.tags-list-item {
	height: 24px;
	line-height: 24px;
	border-radius: 4px;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 0px 10px;
	background-color: #4082d3;
	color: #fff;
	font-size: 14px;
	transition: all 100ms linear;
	display: flex;
}

.tags-list-item-button {
	font-size: 12px;
	line-height: 26px;
	margin-left: 8px;
	cursor: pointer;
}

.tags-compose {
	height: 40px;
	border: 1px solid #eee;
	border-radius: 12px;
	padding: 0px 10px;
	color: #333;
	margin-bottom: 10px;
	width: 100%;
	font-size: 14px;
}

.tags-compose:focus {
	border-color: #4082d3;
}

</style>
