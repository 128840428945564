import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [

		{ path: '/', name: 'Dashboard', component: () => import('./components/page/Users') },
		{ path: '/login', name: 'Login', component: () => import('./components/page/Login') },
		{ path: '/logout', name: 'Logout', component: () => import('./components/page/Logout') },

		{ path: '/users', name: 'Users', component: () => import('./components/page/Users') },
		{ path: '/users/:id', name: 'User', component: () => import('./components/page/User') },
		{ path: '/users/:id/delete', name: 'User.Delete', meta: { noun: 'user', endpoint: 'user/:id', back: 'User', complete: 'Users' }, component: () => import('./components/page/Delete') },

		{ path: '/organisations', name: 'Organisations', component: () => import('./components/page/Organisations') },
		{ path: '/organisations/:id', name: 'Organisation', component: () => import('./components/page/Organisation') },
		{ path: '/organisations/:id/delete', name: 'Organisation.Delete', meta: { noun: 'organisation', endpoint: 'organisation/:id', back: 'Organisation', complete: 'Organisations' }, component: () => import('./components/page/Delete') },

		{ path: '/groups', name: 'Groups', component: () => import('./components/page/Groups') },
		{ path: '/groups/:id', name: 'Group', component: () => import('./components/page/Group') },
		{ path: '/groups/:id/delete', name: 'Group.Delete', meta: { noun: 'group', endpoint: 'group/:id', back: 'Group', complete: 'Groups' }, component: () => import('./components/page/Delete') },

		{ path: '/centres', name: 'Centres', component: () => import('./components/page/Centres') },
		{ path: '/centres/:id', name: 'Centre', component: () => import('./components/page/Centre') },
		{ path: '/centres/:id/delete', name: 'Centre.Delete', meta: { noun: 'centre', endpoint: 'centre/:id', back: 'Centre', complete: 'Centres' }, component: () => import('./components/page/Delete') }
	]
})