<template>
	
<div class="screen" :class="{'is-loading': loading}">

	<com-menu v-if="$loggedin" />

	<com-content v-if="$loggedin || $route.name === 'Login'" :loggedin="$loggedin">

		<slot></slot>

	</com-content>

	<app-icon icon="loading" v-if="loading" class="screen-loading" />

</div>

</template>

<script>

import comMenu from './screen/Menu'
import comContent from './screen/Content'

export default {

	props: ['loading'],

	components: {
		comMenu,
		comContent
	},

	computed: {

		$loggedin: function() {

			return this.$store.getters['authorised']

		}

	}

}

</script>

<style scoped>

.screen {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	background-color: #fff;
}

.screen.is-loading {
	background-color: #5b108b;
}

.screen-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #fff;
}

</style>