<template>

<div class="foot">

	<div class="foot-total">

		{{ total }} result<template v-if="total > 1">s</template>

	</div>

	<div class="foot-pages" v-if="pages > 1">

		<router-link :to="{ name: (i > 1) ? $route.meta.page + '.Page' : $route.meta.page, params: { page: i } }" class="foot-pages-item" :class="{'is-current': pagination.page === i}" v-for="i in pages" :key="i">{{ i }}</router-link>

	</div>

</div>

</template>

<script>

export default {

	props: ['pagination', 'total'],

	computed: {

		pages: function() {

			return Math.ceil(this.total / this.pagination.perpage)

		}

	}
	
}

</script>

<style scoped>

.foot {
	display: flex;
	padding: 0px 10px;
	border-top: 1px solid #f2f2f2;
	padding: 10px 0px;
	align-items: center;
}

.foot-total {
	color: #687589;
	font-size: 14px;
	padding: 0px 10px;
	flex-shrink: 0;
}

.foot-pages {
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	padding-right: 10px;
}

.foot-pages-item {
	font-size: 14px;
	color: #687589;
	margin-left: 5px;
	background-color: #eee;
	border-radius: 4px;
	min-width: 22px;
	height: 22px;
	text-align: center;
	line-height: 22px;
}

.foot-pages-item:hover {
	background-color: #687589;
	color: #fff;
}

.foot-pages-item.is-current {
	background-color: #ffbe7a;
	color: #fff;
}

</style>
