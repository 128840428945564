<template>

<div class="body">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

</style>
