<template>

<app-table-cell class="icon" :align="align">

	<app-icon :icon="icon" />

</app-table-cell>

</template>

<script>

export default {

	props: ['icon', 'align']

}

</script>

<style scoped>

.icon {
	font-size: 12px;
	line-height: 16px;
}

</style>
