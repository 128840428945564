<template>

<div class="head">

	<app-content-head-title v-if="title" :back="back" :backParams="backParams" :back-path="backPath" :title="title" :subtitle="subtitle" :image="image" />
	<app-content-head-title v-if="mobileTitle && window.is.mobile" :title="mobileTitle" :subtitle="subtitle" />

	<app-content-head-actions v-if="hasActions">

		<slot></slot>
		
	</app-content-head-actions>

</div>

</template>

<script>

export default {

	props: ['title', 'mobileTitle', 'back', 'backParams', 'backPath', 'subtitle', 'image'],

	computed: {

		hasActions: function() {

			return !!this.$slots.default

		}

	}

}

</script>

<style scoped>

.head {
	width: 100%;
	background-color: #fff;
	height: 80px;
	padding-right: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-shrink: 0;
	border-bottom: 1px solid #eee;
}

.is-tablet .head {
	height: 64px;
	padding-right: 0px;
}

.is-mobile .head {
	height: 48px;
	padding-right: 0px;
}

</style>
