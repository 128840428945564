<template>

<div class="inner" :class="{'is-fullscreen': fullscreen || loading, 'is-centered': centered}">

	<template v-if="!loading">

		<slot></slot>

	</template>

	<app-icon icon="loading" class="content-loading" v-if="loading" />

</div>
	
</template>

<script>

export default {

	props: ['fullscreen', 'centered', 'loading']

}

</script>

<style scoped>

.inner.is-fullscreen {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.inner.is-centered {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.content-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #5b108b;
}

</style>