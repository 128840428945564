<template>

<div class="check" v-tooltip="'Click to toggle'" :class="{'is-active': value}" v-on:click="$emit('input', !value)">

	<div class="check-text">{{ text }}</div>
	<app-icon icon="tick" class="check-icon" />

</div>

</template>

<script>

export default {

	props: ['value', 'text']

}

</script>

<style scoped>

.check {
	display: flex;
	padding: 0px 10px;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	color: #687589;
}

.check-text {
	font-size: 12px;
	padding-right: 5px;
}

.check-icon {
	font-size: 16px;
	color: #eee;
	margin-top: 2px;
}

.check:hover {
	color: #333;
}

.check:hover .check-icon {
	color: #ccc;
}

.check.is-active .check-icon {
	color: #ffbe7a;
}

</style>
