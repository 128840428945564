<template>

<app-input class="select" :wide="wide" :validation="validation" :class="{'has-value': value, 'is-expanded': isExpanded}" :label="label" :stacked="stacked" :no-line="noLine" :is-ready="value.length" :notes="notes" :disabled="disabled">

	<div class="select-value" v-on:click="onToggleClick">

		<template v-if="value">{{ valueText }}</template>
		<template v-if="!value">{{ placeholder }}</template>

		<app-icon icon="caret-down" class="select-value-icon" />
		
	</div>

	<div class="select-list" v-if="isExpanded && !isKeyed">

		<div class="select-list-item" :class="{'is-active': key === value}" v-for="(option, key) in allowedOptions" :key="key" v-on:click="onOptionClick(key)">
			
			<div class="select-list-item-icon" v-if="icons"><app-icon :icon="icons[key]" /></div>
			<div class="select-list-item-text">{{ option }}</div>
			
		</div>

	</div>

	<div class="select-list" v-if="isExpanded && isKeyed">

		<div class="select-list-item" :class="{'is-active': option[valueProperty] === value}" v-for="option in allowedOptions" :key="option[valueProperty]" v-on:click="onOptionClick(option[valueProperty])">
			
			<div class="select-list-item-icon" v-if="icons"><app-icon :icon="icons[option[valueProperty]]" /></div>
			<div class="select-list-item-text">{{ option.text }}</div>
			
		</div>

	</div>
	
</app-input>

</template>

<script>

export default {

	props: ['validation', 'wide', 'label', 'allowDeselect', 'property', 'disabled', 'value', 'icons', 'options', 'noLine', 'store', 'stacked', 'placeholder', 'notes'],

	data: function() {

		return {
			isExpanded: false
		}

	},

	computed: {

		allowedOptions: function() {

			var options = this.$util.copy(this.options)

			if (this.allowDeselect) {

				if (this.isKeyed) {

					options.unshift({
						[this.valueProperty]: 0,
						text: this.placeholder
					})

				} else {

					options[0] = this.placeholder

				}

			}

			return options

		},

		valueProperty: function() {

			return this.property || 'value'

		},

		isKeyed: function() {

			return this.$_.isObject(this.options[this.$_.keys(this.options)[0]])

		},

		valueText: function() {

			var propertyName = this.valueProperty

			return (this.isKeyed) ? this.$_.findWhere(this.options, {
				[propertyName]: this.value
			}).text : this.options[this.value]

		}

	},

	methods: {

		onToggleClick: function() {

			this.isExpanded = !this.isExpanded

		},

		onOptionClick: function(value) {
			
			this.isExpanded = false
			
			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.select-value {
	line-height: 36px;
	font-size: 14px;
	flex-grow: 1;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 12px;
	border: 1px solid #eee;
	cursor: pointer;
}

.select-value:hover {
	border-color: #4082d3;
}

.select.is-expanded .select-value {
	border-color: #4082d3;
	border-radius: 12px 12px 0px 0px;
}

.select-value-icon {
	color: #999;
	position: absolute;
	right: 10px;
	font-size: 16px;
	top: 2px;
}

.select.is-expanded .select-value-icon,
.select-value:hover .select-value-icon {
	color: #4082d3;
}

.select-list {
	position: absolute;
	z-index: 2;
	left: 0px;
	padding: 4px 0px;
	width: 100%;
	background-color: #4082d3;
	border-radius: 0px 0px 12px 12px;
}

.select-list-item {
	padding: 2px 10px;
	font-size: 14px;
	color: #fff;
	line-height: 20px;
	cursor: pointer;
	display: flex;
}

.select-list-item.is-active {
	font-weight: 400;
}

.select-list-item-icon {
	flex-shrink: 0;
	width: 24px;
	font-size: 16px;
}

.select-list-item-text {
	flex-grow: 1;
}

</style>
