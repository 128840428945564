<template>

<div class="head" :style="{gridTemplateColumns: layout}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['layout']
	
}

</script>

<style scoped>

.head {
	display: grid;
	grid-gap: 10px;
	padding: 0px 10px;
	border-bottom: 1px solid #f2f2f2;
}

</style>
