<template>

<app-input :prefix="prefix" :label="label" :stacked="stacked" :no-line="noLine" :notes="notes" :disabled="disabled" :validation="validation">

	<transition-group name="list" tag="div" class="inputs">

		<div class="inputs-item" v-for="(item, index) in value" :key="item.id">

			<div class="inputs-item-name">{{ item.text }}</div>

			<div class="inputs-item-buttons">

				<app-icon icon="edit" v-tooltip="'Edit option'" class="inputs-item-button" v-on:click.stop.native="onEditClick(index)" />
				<app-icon icon="delete" v-tooltip="'Delete option'" class="inputs-item-button is-danger" v-on:click.native="onDeleteClick(index)" />
				<app-icon icon="caret-up" v-tooltip="'Move up'" class="inputs-item-button is-neutral" v-on:click.native="onLeftClick(index)" :disabled="index === 0" />
				<app-icon icon="caret-down" v-tooltip="'Move down'" class="inputs-item-button is-neutral" v-on:click.native="onRightClick(index)" :disabled="index === value.length - 1" />

			</div>

		</div>

		<div v-if="!value.length" class="inputs-item is-insert" key="insert">
			<div class="inputs-item-name">{{ placeholder }}</div>
		</div>

	</transition-group>

	<app-icon icon="create-circle" class="input-add" v-tooltip="'Add option'" v-on:click.native="onInsertClick" />

	<com-option v-if="is.expanded" :item="selectedItem" v-on:close="onPanelClose" v-on:save="onItemSave" />

</app-input>

</template>

<script>

import comOption from './options/Option'
import Vue from 'vue'

export default {

	props: ['label', 'password', 'toggle', 'prefix', 'validation', 'numeric', 'autogrow', 'disabled', 'value', 'noLine', 'stacked', 'placeholder', 'notes', 'maxlength'],

	components: {
		'com-option': comOption
	},

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		selectedItem: function() {

			return (this.is.expanded === true || this.is.expanded === false) ? false : this.$util.copy(this.value[this.is.expanded - 1])

		}

	},

	methods: {

		onLeftClick: function(index) {

			if (index > 0) {

				var value = this.$util.copy(this.value)

				var removed = value.splice(index, 1)

				value.splice(index - 1, 0, removed[0])

				this.$emit('input', value)

			}

		},

		onRightClick: function(index) {

			if (index < this.value.length) {

				var value = this.$util.copy(this.value)

				var removed = value.splice(index, 1)

				value.splice(index + 1, 0, removed[0])

				this.$emit('input', value)

			}

		},

		onDeleteClick: function(index) {

			var value = this.$util.copy(this.value)

			value.splice(index, 1)

			this.$emit('input', value)

		},

		onEditClick: function(index) {

			this.is.expanded = index + 1

			this.$pubsub.$emit('offset', this.is.expanded)

		},

		onItemSave: function(model) {

			var value = this.$util.copy(this.value)

			if (this.is.expanded === true) {

				value.push(model)

			} else {

				Vue.set(value, this.is.expanded - 1, model)

			}

			this.$emit('input', value)

			this.onPanelClose()

		},

		onInsertClick: function() {

			if (!this.is.expanded) {

				this.is.expanded = true

				this.$pubsub.$emit('offset', this.is.expanded)

			}

		},

		onPanelClose: function() {

			this.is.expanded = false

			this.$pubsub.$emit('offset', this.is.expanded)

		}

	}

}

</script>

<style scoped>

.list-move,
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
}

.list-leave-active {
	position: absolute;
}

.inputs {
	display: flex;
	flex-direction: column;
}

.inputs-item {
	border-bottom: 1px solid #eee;
	min-height: 40px;
	display: flex;
	align-items: center;
	color: #343434;
	background-color: #fff;
}

.inputs-item:first-child {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.inputs-item:last-child {
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	border-bottom-width: 0px;
}

.inputs-item-name {
	flex-grow: 1;
	font-size: 14px;
	flex-basis: 0;
	padding: 4px 10px;
	flex-shrink: 0;
}

.inputs-item-type {
	padding-right: 10px;
	font-size: 14px;
	width: 160px;
}

.inputs-item-buttons {
	flex-shrink: 0;
	display: flex;
}

.inputs-item-button {
	width: 24px;
	height: 24px;
	cursor: pointer;
	font-size: 14px;
	border-radius: 4px;
	line-height: 26px;
	text-align: center;
	background-color: #4082d3;
	color: #fff;
	margin-right: 5px;
}

.inputs-item-button.is-neutral {
	background-color: #ddd;
	color: #666;
}

.inputs-item-button.is-danger {
	background-color: #c55b5b;
}

.input-add {
	font-size: 24px;
	color: #4082d3;
	position: absolute;
	left: -32px;
	cursor: pointer;
	top: 8px;
}

.inputs-item.is-insert .inputs-item-name {
	color: #999;
}

.input.is-stacked .input-add {
	right: 0px;
	left: auto;
	top: -32px;
}

</style>
