<template>

<app-table-cell class="text" :class="{'is-empty': canEmpty && !text}" :align="align">

	<template v-if="text || !canEmpty">{{ text }}</template><template v-if="canEmpty && !text">n/a</template><small v-if="subtext && text">{{ subtext }}</small>

</app-table-cell>

</template>

<script>

export default {

	props: ['text', 'subtext', 'align', 'canEmpty']

}

</script>

<style scoped>

.text.is-empty {
	color: #ddd;
}

.text {
	flex-direction: column;
}

.text small {
	font-size: 11px;
	opacity: 0.6;
	margin-top: 2px;
}

</style>
