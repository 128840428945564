<template>

<app-input class="suggest" :class="{'has-value': value, 'is-creatable': canCreate, 'has-image': image || color || icon, 'is-suggesting': suggestions.length}" :validation="validation" :label="label" :stacked="stacked" :no-line="noLine" :is-ready="value" :notes="notes" :disabled="disabled">

	<div class="suggest-search" :class="{'is-focus': isFocus}">

		<div class="suggest-search-image" :class="{'has-color': color}" v-if="value && ((image || color) && !icon)" v-bgimage="imageSource" v-bgcolor="colorSource">

			<app-icon icon="noimage" class="suggest-search-image-missing" v-if="!imageSource && image" />

		</div>

		<app-icon :icon="icon[source.type]" class="suggest-search-preicon" :class="{'has-color': color}" v-if="value && ((icon || color) && !image)" />

		<input type="text" ref="input" class="suggest-search-text" v-model="search" :placeholder="placeholder" v-on:keydown="onKeyDown" v-on:focus="isFocus = true" v-on:blur="isFocus = false" />

		<app-icon icon="search" class="suggest-search-icon" />

		<div class="suggest-search-button" v-if="value" v-tooltip="'Change'" v-on:click="onChangeClick">

			<app-icon icon="customise" />

		</div>

		<div class="suggest-search-button" v-if="canCreate && !value" v-tooltip="'Create'" v-on:click="onCreateClick">

			<app-icon icon="create-cricle" />

		</div>

	</div>

	<div class="suggest-list" v-if="suggestions.length" v-on:mouseenter="isOver = true" v-on:mouseleave="isOver = false">

		<div class="suggest-list-item" v-for="(suggestion, index) in suggestions" :class="{'is-highlight': index === highlight - 1}" :key="suggestion.id" v-on:click="onSuggestionClick(suggestion.id)">
			
			{{ suggestion.name }}
			
		</div>

	</div>
	
</app-input>

</template>

<script>

import Vue from 'vue'

export default {

	props: ['validation', 'label', 'endpoint', 'disabled', 'params', 'value', 'icon', 'color', 'image', 'noLine', 'store', 'canCreate', 'showAll', 'stacked', 'placeholder', 'notes'],

	data: function() {

		return {
			isFocus: false,
			isOver: false,
			search: '',
			highlight: 0,
			source: {},
			apiSuggestions: []
		}

	},

	computed: {

		imageSource: function() {

			return (this.image) ? this.source[this.image] : false

		},

		colorSource: function() {

			return (this.color) ? this.source[this.color] : false

		},

		suggestions: function() {

			if (this.value) return []

			if (this.search && (this.isOver || this.isFocus)) {

				return this.apiSuggestions

			} else if (this.showAll && (this.isOver || this.isFocus)) {

				return this.apiSuggestions

			} else {

				return []

			}

		}

	},

	created: function() {

		if (this.value) this.getValueName()

	},

	watch: {

		suggestions: function() {

			this.highlight = 0

		},
	
		value: function(n) {

			if (n) {
				
				this.getValueName()

			} else {

				this.search = ''

			}

		},

		search: async function(n) {

			if (n && !this.value) {

				this.$api.cancel()

				var localParams = this.$util.copy(this.params || {})

				localParams.text = this.search

				this.$api.cancel()

				await this.$api.post(['suggest', this.endpoint], localParams).then(function(json) {

					this.apiSuggestions = json

				}.bind(this))

			}

		}

	},

	methods: {

		getValueName: async function() {

			await this.$api.get([this.endpoint, this.value]).then(function(json) {

				Vue.set(this, 'source', json)
				this.search = this.source.name

			}.bind(this))

		},

		onKeyDown: function(e) {

			if (e.key === 'Enter') {

				e.preventDefault()

				if (this.suggestions.length) {
					
					this.onSuggestionClick((this.highlight) ? this.suggestions[this.highlight - 1].id : this.suggestions[0].id)
					this.$refs.input.blur()

				}

			} else if (e.key === 'Tab' || e.key === 'ArrowDown') {

				e.preventDefault()

				if (this.highlight < this.suggestions.length) this.highlight++
				
			} else if (e.key === 'ArrowUp') {

				e.preventDefault()

				if (this.highlight > 0) this.highlight--
				
			}

		},

		onChangeClick: function() {

			this.search = ''

			this.$refs.input.focus()
			
			this.$emit('input', false)

		},

		onCreateClick: function() {

		},

		onSuggestionClick: function(value) {
			
			this.$emit('input', value)

			this.$refs.input.blur()

			this.isOver = false

		}

	}

}

</script>

<style scoped>

.suggest-search {
	width: 100%;
	color: #333;
	display: flex;
}

.suggest-search-image {
	width: 40px;
	height: 40px;
	background-color: #eee;
	background-size: cover;
	border-radius: 12px 0px 0px 12px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.suggest-search-preicon {
	width: 40px;
	height: 40px;
	line-height: 32px;
	text-align: center;
	background-color: #4082d3;
	color: #fff;
	border: 4px solid transparent;
	border-radius: 12px 0px 0px 12px;
}

.suggest-search-image.has-color {
	border-width: 10px 8px 10px 10px;
	width: 48px;
}

.suggest-search-image-missing {
	font-size: 14px;
	color: #999;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.suggest-search-text {
	line-height: 36px;
	font-size: 14px;
	flex-grow: 1;
	padding: 0px 10px;
	color: #333;
	background-color: #fff;
	border-radius: 12px;
	border: 1px solid #eee;
}

.suggest-search-icon {
	color: #ddd;
	position: absolute;
	right: 12px;
	top: 12px;
	font-size: 16px;
}

.suggest.has-value .suggest-search-text {
	border-radius: 12px 0px 0px 12px;
	border-right-width: 0px;
	background-color: #eee;
	pointer-events: none;
}

.suggest.is-creatable .suggest-search-text {
	border-radius: 12px 0px 0px 12px;
	border-right-width: 0px;
}

.suggest.has-value.has-image .suggest-search-text {
	border-radius: 0px 0px 0px 0px;
}

.suggest.is-suggesting .suggest-search-text {
	border-radius: 12px 12px 0px 0px;
}

.suggest.is-suggesting.has-image.has-value .suggest-search-text {
	border-radius: 0px 12px 0px 0px;
}

.suggest-search-text:focus {
	border-color: #4082d3;
}

.suggest-search-button {
	flex-shrink: 0;
	border: 1px solid #eee;
	border-radius: 0px 12px 12px 0px;
	border-left-width: 0px;
	background-color: #fff;
	width: 36px;
	line-height: 36px;
	text-align: center;
	color: #4082d3;
	cursor: pointer;
	font-size: 16px;
	transition: all 100ms linear;
	background-color: #4082d3;
	border-color: #4082d3;
	color: #fff;
}

.suggest-list {
	position: absolute;
	z-index: 2;
	left: 0px;
	padding: 4px 0px;
	width: 100%;
	background-color: #4082d3;
	border-radius: 0px 0px 12px 12px;
}

.suggest-list-item {
	padding: 4px 10px;
	font-size: 14px;
	color: #fff;
	line-height: 20px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
}

.suggest-list-item:nth-child(even) {
	background-color: rgba(255, 255, 255, 0.1);
}

.suggest-list-item:hover,
.suggest-list-item.is-highlight {
	background-color: rgba(0, 0, 0, 0.15);
}

.suggest-list-item small {
	display: block;
	font-size: 12px;
	line-height: 12px;
}

.suggest-list-item-icon {
	font-size: 16px;
	width: 24px;
}

</style>
