<template>

<div class="row" :class="{'is-selected': selected, 'is-top': align === 'top', 'is-danger': danger, 'is-warning': warning}" :style="{gridTemplateColumns: layout}" v-on:click="$emit('click')">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['layout', 'selected', 'danger', 'align', 'warning']

}

</script>

<style scoped>

.row {
	display: grid;
	grid-gap: 10px;
	align-items: center;
	padding: 0px 10px;
}

.row.is-top {
	align-items: flex-start;
}

.row.is-top >>> .cell {
	padding-top: 10px!important;
}

.row:nth-child(even) {
	background-color: #fafafa;
}

.row:hover {
	background-color: #ffbe7a;
	cursor: pointer;
}

.row.is-warning {
	background-color: #fff3e7;
}

.row.is-danger {
	background-color: #F2DEDE;
}

.row.is-selected {
	background-color: #d9eaff;
}

</style>
