export default {

	store: {
		commit: {
			update: 1,
			add: 2,
			remove: 3
		}
	},

	roleName: {
		global: 'Global Admin',
		organisation: 'Organisation Admin',
		contributor: 'Contributor',
		user: 'User'
	},

	role: {
		global: 'global',
		organisation: 'organisation',
		contributor: 'contributor',
		user: 'user'
	}

}