<template>

<div class="menu">

	<div class="menu-logo" />

	<div class="menu-items is-main">

		<router-link :to="{ name: 'Users' }" class="menu-items-link">Users</router-link>
		<router-link v-if="$manageOrganisations" :to="{ name: 'Organisations' }" class="menu-items-link">Organisations</router-link>
		<router-link :to="{ name: 'Groups' }" class="menu-items-link">Groups</router-link>
		<router-link :to="{ name: 'Centres' }" class="menu-items-link">Local Centres</router-link>

	</div>

	<div class="menu-items">

		<router-link :to="{ name: 'Logout' }" class="menu-items-link">Logout</router-link>

	</div>

</div>
	
</template>

<script>

export default {

}

</script>

<style scoped>

.menu {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 240px;
	background-color: #5b108b;
	display: flex;
	flex-direction: column;
}

.menu-logo {
	height: 100px;
	margin: 8px;
	background-image: url(https://www.alzscot.org/themes/custom/numiko/src/img/svg/alzscot_logo_white.svg);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.menu-items {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.menu-items.is-main {
	flex-grow: 1;
}

.menu-items-link {
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
	padding: 8px 8px;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.menu-items-link.router-link-exact-active {
	background-color: #fff;
	color: #5b108b;
}

.menu-items-link:first-child {
	border-top-width: 0px;
}

.menu-items-link:not(.router-link-exact-active):hover {
	background-color: rgba(0, 0, 0, 0.2);
}

</style>