<template>

<app-table-cell class="column" :align="align">

	{{ text }}

	<app-icon class="column-icon" icon="caret-down" v-if="sorted" />

</app-table-cell>

</template>

<script>

export default {

	props: ['text', 'align', 'sorted']

}

</script>

<style scoped>

.column {
	font-weight: 400;
}

.column-icon {
	margin-left: 10px;
	font-size: 12px;
}

</style>
