<template>

<com-screen id="app" :loading="is.loading">

	<router-view />

</com-screen>

</template>

<script>

import axios from 'axios'

import comScreen from './components/Screen'

export default {

	name: 'App',

	components: {
		comScreen
	},

	data: function() {

		return {
			is: {
				initiated: false,
				loading: true
			}
		}

	},

	watch: {

		$route: {

			deep: true,

			handler: function() {

				if (this.$route.name && !this.is.initiated) this.init()

			}

		}	

	},

	created: function() {

		if (this.$route.name) this.init()

	},

	methods: {

		init: async function() {

			await this.$store.dispatch('init')

			this.is.initiated = true

			if (this.$route.query.code) {

				this.validate()

			} else if (this.$store.getters['token'] && this.$store.getters['token'] !== false) {

				this.authorise()

			} else if (this.$route.name !== 'Login') {

				this.is.loading = false

				this.$router.push({
					name: 'Login'
				})

			} else {

				this.is.loading = false

			}	
			
		},

		validate: async function() {

			await axios.post('login', {
				state: this.$route.query.state,
				code: this.$route.query.code
			}).then(function(response) {

				this.$store.commit('authorised', true)
				this.$store.commit('token', response.data.token)
				this.$store.commit('organisation', response.data.organisation)
				this.$store.commit('name', response.data.name)
				this.$store.commit('role', response.data.role)

				this.is.loading = false

				if (this.$route.name === 'Login') {

					this.$router.push({
						name: 'Users'
					})
						
				}

			}.bind(this), function(response) {

				this.$store.commit('token', false)

				this.is.loading = false

				this.$router.push({
					name: 'Login',
					params: {
						reason: response.response.data.message
					}
				})

			}.bind(this))

		},

		authorise: async function() {

			await axios.get('session').then(function(response) {

				this.$store.commit('authorised', true)
				this.$store.commit('name', response.data.name)
				this.$store.commit('organisation', response.data.organisation)
				this.$store.commit('role', response.data.role)

				this.is.loading = false

				if (this.$route.name === 'Login') {

					this.$router.push({
						name: 'Users'
					})
						
				}
				
			}.bind(this), function() {

				this.$store.commit('token', false)

				this.is.loading = false

				this.$router.push({
					name: 'Login'
				})

			}.bind(this))

		}

	}

}

</script>

<style>

body {
	background-color: #5b108b;
}

</style>
