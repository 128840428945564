<template>

<div class="cell" :class="{[clsAlign]: true}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['align'],

	computed: {

		clsAlign: function() {

			return (this.align) ? 'align-' + this.align.toLowerCase() : false

		}

	}

}

</script>

<style scoped>

.cell {
	padding: 10px 0px;
	font-size: 14px;
	color: #687589;
	font-weight: 300;
	display: flex;
}

.cell.align-center {
	justify-content: center;
	align-items: center;
}

</style>
