<template>

<div class="body" :class="{'is-empty': isEmpty, 'is-loading': loading}">

	<template v-if="!isEmpty">

		<slot></slot>

	</template>

	<div class="body-empty" v-if="isEmpty">

		<app-icon icon="empty" class="body-empty-icon" />

		<div class="body-empty-text">{{ placeholder }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['isEmpty', 'placeholder', 'loading']

}

</script>

<style scoped>

.body {
	padding: 10px;
	transition: opacity 100ms ease-in-out;
	flex-grow: 1;
	overflow-y: auto;
	min-height: calc(100vh - 160px);
}

.body.is-loading {
	opacity: 0.25;
}

.body.is-empty {
	height: calc(100vh - 160px);
}

.body-empty {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #a7b6c6;
}

.body-empty-icon {
	font-size: 96px;
}

.body-empty-text {
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0px;
}

</style>
