<template>

<div class="tags">

	<div class="tags-item" v-tooltip="'Click to toggle tag'" :class="{'is-active': !value.length || $_.contains(value, option.value)}" :data-key="option.value" v-for="option in options" :key="option.value" v-on:click="onToggleClick(option.value)">

		{{ option.text }}

	</div>

</div>

</template>

<script>

export default {

	props: ['value', 'options'],

	methods: {

		onToggleClick: function(value) {

			var values = this.$util.copy(this.value)

			this.$util.array.toggle(values, value)

			if (values.length === this.options.length) values = []

			this.$emit('input', values)

		}
		
	}

}

</script>

<style scoped>

.tags {
	display: flex;
	padding: 4px;
}

.tags-item {
	height: 24px;
	line-height: 22px;
	padding: 0px 5px;
	border-radius: 4px;
	margin: 0px 2px;
	font-size: 12px;
	cursor: pointer;
	background-color: #ffbe7a;
	border: 1px solid #ffbe7a;
	color: #4d5b6e;
	opacity: 0.25;
	transition: opacity 100ms linear;
}

.tags-item:hover,
.tags-item.is-active {
	opacity: 1;
}

</style>
