import Vue from "vue"

export default {

	computed: {

		$role: function() {

			return this.$store.getters['role'] 

		},

		$manageOrganisations: function() {

			return this.$role === this.$constants.role.global

		}

	},

	methods: {

		$endpoint: function(path) {

			this.$_.each(this.$route.params, function(value, key) {

				path = path.replace(':' + key, value)

			})

			return path

		},

		$notify: function(config) {

			if (this.$_.isArray(config.message)) config.message = config.message.join(' ')

			config.message = config.message.replace(' .', '.')

			Vue.$toast.open(config)

		}

	}

}