<template>

<div class="actions">

	<slot></slot>
		
</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.actions {
	display: flex;
}

.is-device .actions {
	border-right-width: 0px;
}

.is-mobile .actions {
	border: 0px;
	flex-grow: 1;
	justify-content: flex-end;
}

</style>
