import axios from 'axios'
import _ from 'underscore'

const CancelToken = axios.CancelToken

export default {

	source: false,

	cancel: function() {

		if (this.source) this.source.cancel()

	},

	get: function(url, params, page) {

		params = params || {}
		page = page || false

		if (page) params['page'] = page

		this.source = CancelToken.source()

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			axios.get(url, {
				cancelToken: this.source.token,
				headers: {
					'Cache-Control': 'no-cache',
					'Pragma': 'no-cache',
					'Expires': '0',
				},
				params: params
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject({})

				}

			}.bind(this), function(json) {

				reject(json.response.data)

			})

		}.bind(this))

	},

	post: function(url, params) {

		params = params || {}

		this.source = CancelToken.source()

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			axios.post(url, params, {
				cancelToken: this.source.token
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject({})

				}

			}.bind(this), function(json) {

				reject(json.response.data)

			})

		}.bind(this))

	},

	patch: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			axios.patch(this.api + url, params).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject({})

				}

			}.bind(this), function(json) {

				reject(json.response.data)

			})

		}.bind(this))

	},

	put: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			axios.put(url, params).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject({})

				}

			}.bind(this), function(json) {

				reject(json.response.data)

			})

		}.bind(this))

	},

	delete: function(url) {

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			axios.delete(url).then(function() {

				this.response(resolve)


			}.bind(this), function(json) {

				reject(json.response.data)

			})

		}.bind(this))

	},

	response: function(resolve, response) {

		response = response || {}

		resolve(response.data)

	}

}