<template>

<div class="date">

	<div class="date-button" v-tooltip="tooltip" v-on:click="onButtonClick">

		<div class="date-button-text">{{ textValue }}</div>

		<app-icon class="date-button-icon" icon="caret-down" />

	</div>

	<app-panel v-if="expanded" v-on:close="onButtonClick">
		
		<app-panel-title :text="text" />

		<div class="date-calendar">
			
			<v-date-picker mode="date" :available-dates="{ start: startDate, end: endDate }" :min-date="startDate" :max-date="endDate" v-model="dateValue" ncolor="gray" is-expanded />

		</div>

	</app-panel>

</div>

</template>

<script>

export default {

	props: ['value', 'text', 'tooltip', 'options', 'min', 'max'],

	data: function() {

		return {
			expanded: false,
			dateValue: new Date()
		}

	},

	created: function() {

		if (this.value) this.dateValue = this.$moment.unix(this.value).toDate()

	},

	watch: {

		dateValue: function(n) {

			this.$emit('input', (n === null) ? 0 : this.$moment(n).unix())

		}

	},

	computed: {

		textValue: function() {

			return (this.value) ? this.text + ' ' + this.$moment.unix(this.value).format('MMMM Do') : this.text

		},

		startDate: function() {

			return (this.min) ? this.$moment.unix(this.min).toDate() : false

		},

		endDate: function() {

			return (this.max) ? this.$moment.unix(this.max).toDate() : false

		},

		active: function() {

			return this.value

		}

	},

	methods: {

		onButtonClick: function() {

			this.expanded = !this.expanded

			this.$pubsub.$emit('offset', this.expanded)

		},

		onToggle: function(id) {

			this.$emit('input', id)

			this.onButtonClick()

		}

	}

}

</script>

<style scoped>

.date-button {
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
	cursor: pointer;
	color: #687589;
}

.date-button-count {
	border-radius: 4px;
	background-color: #ffbe7a;
    color: #4d5b6e;
	font-size: 10px;
	height: 14px;
	min-width: 14px;
	text-align: center;
	line-height: 14px;
	margin-left: 10px;
}

.date-button:hover {
	color: #333;
}

.date-button-text {
	font-size: 12px;
}

.date-button-icon {
	font-size: 12px;
	margin-left: 10px;
}

.date-calendar {
	padding: 10px 20px;
}

</style>
