import { render, staticRenderFns } from "./Box.vue?vue&type=template&id=7aa34ea0&scoped=true&"
import script from "./Box.vue?vue&type=script&lang=js&"
export * from "./Box.vue?vue&type=script&lang=js&"
import style0 from "./Box.vue?vue&type=style&index=0&id=7aa34ea0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa34ea0",
  null
  
)

export default component.exports