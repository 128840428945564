<template>

<app-table-cell class="action" :align="align" v-on:click.stop.native="$emit('click')">

	<div class="action-button">

		<app-icon :icon="icon" />

	</div>

</app-table-cell>

</template>

<script>

export default {

	props: ['icon', 'align']

}

</script>

<style scoped>

.action {
	text-align: right;
	padding: 0px;
}

.action-button {
	height: 24px;
	width: 24px;
	cursor: pointer;
	font-size: 16px;
	border-radius: 4px;
	line-height: 26px;
	background-color: #4082d3;
	text-align: center;
	color: #fff;
}

.action-button:hover {
	background-color: #236dc9;
}

</style>
